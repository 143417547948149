<template>
    <v-container class="staff" grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Staffs Identity Card
                        <v-spacer></v-spacer>
                        <download-button v-if="form.items.data.length" @action="printBulk">PRINT ID Card</download-button>

                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            footer-props.items-per-page-options="rowsPerPageItems"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td align="left">{{ index + form.items.meta.from }}</td>
                                <td align="left" class="text-xs-left">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <img :src="item.profile_image">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <small><strong>{{item.full_name}}</strong></small>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="grey--text text--lighten-1">
                                                <small v-for="(role,ri) in item.roles" :key="ri">
                                                    <span v-if="ri<=6">{{role.name}}<span
                                                            v-if="ri<(item.roles.length-1)">,</span></span>
                                                </small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </td>
                                <td class="text-xs-left">
                                    <v-icon small color="primary">email</v-icon>
                                    {{item.email || 'N/A'}} <br>
                                    <v-icon color="warning" small>phone</v-icon>
                                    <strong>{{item.primary_mobile || 'N/A'}}</strong>
                                </td>
                                <td class="text-xs-left">
                                    {{item.created_at}}
                                </td>
                                <td class="text-xs-right">
                                    <add-button @action="printSingle(item.id)" :permission="'section-read'" icon="print">
                                        Print
                                    </add-button>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'
    import AddressHelper from '@/library/AddressHelper'
    import {getPropertyFromArrayObject} from '@/library/helpers';

    import statesData from '@/assets/json/states.json'
    import districtDataData from '@/assets/json/districts.json'
    import cityData from '@/assets/json/cities.json'
    import {mapState} from 'vuex';

    export default {
        data: () => ({
            filter: false,
            cropDialog: false,
            imgSrc: '',
            cropImg: '',
            uploadCropped: {},
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 25,
            },
            form: new Form({
                dialog: false,
                image: {}
            }, '/api/user'),
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            search: '',
            statesData,
            districtDataData,
            cityData,
            headers: [
                {text: '#', align: 'left', value: 'sno', width: 5, sortable: false},
                {text: 'Name', align: 'left', value: 'fname', width: 50},
                {text: 'Contact', align: 'left', value: 'contact', sortable: false, width: 50},
                {text: 'Associated at', align: 'left', value: 'created_at',width: 100},
                {text: 'Action', align: 'center', sortable: false, width: 20}
            ],
            updateState: false,

            roles: [],
            role: '',
            roleLoading: false,
            selectedRole: [],
        }),
        watch: {
            'pagination': function () {
                this.get();
            },
            'members.staff.filter.role': {
                handler(v) {
                    this.get();
                }
            }
        },
        mounted() {
            // this.fetchRoles();
        },
        computed: {
            ...mapState(['members'])
        },
        methods: {
            searchMember: function (v) {
                if (v.length > 2 || !v.length) this.get()
            },
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
					if(typeof json[key] == 'object') {
						let vals = [...json[key]];
                        if(vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
					}
					return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
				}).join('&') + '&search=' + (this.search.toString()) + '&roleId=' + (this.members.staff.filter.role.toString());
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            fetchRoles() {
                this.roleLoading = true;
                this.$rest.get('/api/roles?rowsPerPage=50&sortBy=name').then(({data}) => {
                    if (data.data.length) {
                        this.roles = data.data;
                    }
                }).finally(() => {
                    this.roleLoading = false;
                });
            },
            printBulk() {
                this.$rest.get('/api/print/staff-id-card').then(({data}) => {
                    let url = data.data.download_url;
                    window.open(url)
                })
            },
            printSingle(userId) {
                this.$rest.get('/api/print/staff-id-card').then(({data}) => {
                    let url = data.data.download_url + '?userId=' + userId;
                    window.open(url)
                })
            }
        }
    }
</script>

<style lang="scss">
    .staff {
        .v-list__item {
            padding: 0 !important;
        }
        .v-list__item__title {
            height: 15px;
            line-height: 15px;
        }
    }

    .v-chip .v-avatar {
        margin-right: -10px;
    }
</style>